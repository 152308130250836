
import { computed, defineComponent, reactive, ref } from '@vue/composition-api'
import { cloneDeep, camelCase as toCamelCase } from 'lodash-es'
import { mdiAccountSupervisor, mdiChevronUp, mdiChevronDown } from '@mdi/js'

import CommonTableView from '@/components/common/CommonTableView.vue'

import { useNotify } from '@/store'

import { setSort } from '@/utils/manipulateTableSort'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { useFilter } from '@/composables/useFilter'

import { useDeleteEnumItem, useGetEnumItems } from '@/api/enumItem'
import { EnumItemOutput } from '@/api/types/enumItem'
import { Rights } from '@/api/types/right'

export default defineComponent({
  name: 'EnumItems',
  components: {
    CommonTableView,
    AddEditEnumItemDialog: () => import('@/views/baseData/views/enumItems/components/AddEditEnumItemDialog.vue'),
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
  },
  setup: (_, { root }) => {
    const TABLE_HEADERS = [
      {
        text: root.$t('baseData.enumItems.table.col.title.enumItemAssignment'),
        value: 'enumItemAssignment',
        sortable: true,
      },
      {
        text: root.$t('baseData.enumItems.table.col.title.name'),
        value: 'name',
        sortable: false,
      },
      {
        text: root.$t('baseData.enumItems.table.col.title.actions'),
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const { addNotification } = useNotify()

    const { debouncedCb, vuetifyTableOptions, paginationParams } = useFilter([], init)
    setSort(vuetifyTableOptions, { by: 'enumItemAssignment', desc: false })

    const isAddEditModalOpen = ref(false)

    const isDeleteModalOpen = ref(false)
    const enumItemToDelete = ref<null | EnumItemOutput>(null)

    const { deleteEnumItem: deleteEnumItemXhr } = useDeleteEnumItem()

    async function deleteEnumItem(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await deleteEnumItemXhr(enumItemToDelete.value!.id)

      addNotification({
        text: root.$t(`baseData.enumItems.delete.message`) as string,
        type: 'success',
      })

      isDeleteModalOpen.value = false

      init()
    }

    function onEnumItemDelete(enumItem: EnumItemOutput): void {
      enumItemToDelete.value = cloneDeep(enumItem)

      isDeleteModalOpen.value = true
    }

    function onCloseAddEditDialog(): void {
      isAddEditModalOpen.value = false
    }

    const { exec: getEnumItems, data: enumItems, isLoading, paginationResponse } = useGetEnumItems()

    const totalEnumItems = computed(() => paginationResponse.value.totalElements)

    async function init(): Promise<void> {
      await getEnumItems({ params: { ...paginationParams.value } })
    }

    return reactive({
      icons: {
        mdiAccountSupervisor,
        mdiChevronUp,
        mdiChevronDown,
      },
      constants: {
        Rights,

        TABLE_HEADERS,
      },
      state: {
        enumItems,
        isLoading,

        isAddEditModalOpen,

        isDeleteModalOpen,
        enumItemToDelete,

        totalEnumItems,

        vuetifyTableOptions,
      },
      functions: {
        deleteEnumItem,

        hasSufficientRights,

        debouncedCb,

        toCamelCase,
      },
      listeners: {
        onEnumItemDelete,

        onCloseAddEditDialog,
      },
    })
  },
})
